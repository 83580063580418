[
    "Smith from New York earned $57,309.82 49 minutes ago.",
    "Johnson from Los Angeles earned $68,209.47 12 minutes ago.",
    "Williams from Chicago earned $75,603.27 37 minutes ago.",
    "Brown from Houston earned $62,108.91 25 minutes ago.",
    "Jones from Philadelphia earned $79,408.36 18 minutes ago.",
    "Davis from Phoenix earned $66,507.84 21 minutes ago.",
    "Miller from San Antonio earned $73,209.67 33 minutes ago.",
    "Wilson from San Diego earned $60,902.18 45 minutes ago.",
    "Moore from Dallas earned $77,809.25 28 minutes ago.",
    "Taylor from San Jose earned $69,705.82 40 minutes ago.",
    "Anderson from Jacksonville earned $72,608.74 55 minutes ago.",
    "Thomas from San Francisco earned $68,103.42 42 minutes ago.",
    "Jackson from Indianapolis earned $75,308.97 15 minutes ago.",
    "White from Columbus earned $61,507.84 26 minutes ago.",
    "Harris from Fort Worth earned $78,409.33 31 minutes ago.",
    "Martin from Charlotte earned $64,902.61 39 minutes ago.",
    "Thompson from Detroit earned $71,607.84 47 minutes ago.",
    "García from Berlin earned €53,204.78 4 minutes ago.",
    "Martínez from Hamburg earned €64,908.47 10 minutes ago.",
    "Rodríguez from Munich earned €70,903.52 22 minutes ago.",
    "López from Frankfurt earned €77,608.62 35 minutes ago.",
    "González from Cologne earned €68,502.31 41 minutes ago.",
    "Pérez from Stuttgart earned €74,609.48 20 minutes ago.",
    "Sánchez from Dusseldorf earned €60,504.88 38 minutes ago.",
    "Ramírez from Leipzig earned €79,804.27 44 minutes ago.",
    "Müller from Berlin earned €62,107.89 19 minutes ago.",
    "Schmidt from Hamburg earned €73,204.17 27 minutes ago.",
    "Schneider from Munich earned €61,306.52 36 minutes ago.",
    "Fischer from Frankfurt earned €68,903.27 48 minutes ago.",
    "Weber from Cologne earned €76,509.64 53 minutes ago.",
    "Meyer from Stuttgart earned €70,805.71 29 minutes ago.",
    "Wagner from Dusseldorf earned €66,409.47 34 minutes ago.",
    "Becker from Leipzig earned €79,708.55 58 minutes ago.",
    "Schulz from Berlin earned €58,709.33 50 minutes ago.",
    "Hoffmann from Hamburg earned €64,205.89 52 minutes ago.",
    "Schäfer from Munich earned €71,308.72 59 minutes ago.",
    "Koch from Frankfurt earned €75,604.37 24 minutes ago.",
    "Bauer from Cologne earned €68,504.92 32 minutes ago.",
    "Richter from Stuttgart earned €76,209.85 43 minutes ago.",
    "Klein from Dusseldorf earned €62,906.12 51 minutes ago.",
    "Wolf from Leipzig earned €69,707.93 56 minutes ago.",
    "Schröder from Berlin earned €77,807.91 13 minutes ago.",
    "Neumann from Hamburg earned €60,508.21 23 minutes ago.",
    "Schwarz from Munich earned €79,805.44 30 minutes ago.",
    "Zimmermann from Frankfurt earned €67,703.92 46 minutes ago.",
    "Braun from Cologne earned €75,309.84 54 minutes ago.",
    "Krüger from Berlin earned €62,105.42 17 minutes ago.",
    "Hofmann from Hamburg earned €71,306.76 37 minutes ago.",
    "Hartmann from Munich earned €78,403.91 49 minutes ago.",
    "Lange from Frankfurt earned €64,904.26 57 minutes ago.",
    "Patel from London earned £63,709.84 12 minutes ago.",
    "Rossi from Paris earned €77,808.93 20 minutes ago.",
    "Martens from Milan earned €69,605.84 25 minutes ago.",
    "Jansen from Madrid earned £56,904.73 39 minutes ago.",
    "Kovač from Brussels earned €65,406.22 48 minutes ago.",
    "Novák from Barcelona earned £72,608.46 16 minutes ago.",
    "Nielsen from Amsterdam earned €59,705.44 28 minutes ago.",
    "O'Connor from Vienna earned £67,903.92 35 minutes ago.",
    "Antonopoulos from Rome earned €76,309.81 41 minutes ago.",
    "Ivanov from Berlin earned £78,506.82 50 minutes ago.",
    "Papadopoulos from Frankfurt earned €62,107.93 55 minutes ago.",
    "Andersen from Athens earned £68,402.91 9 minutes ago.",
    "Nielsen from Lisbon earned €79,708.55 18 minutes ago.",
    "Olsen from Madrid earned £61,508.92 27 minutes ago.",
    "Johansson from Brussels earned €70,805.73 32 minutes ago.",
    "Eriksson from London earned £75,603.74 43 minutes ago.",
    "Larsson from Paris earned €64,204.82 58 minutes ago.",
    "Janković from Milan earned £69,904.23 14 minutes ago.",
    "Kowalczyk from Berlin earned €77,808.63 33 minutes ago.",
    "Nowak from Lisbon earned £62,904.32 47 minutes ago.",
    "Al-Mansouri from Riyadh earned $67,209.47 26 minutes ago.",
    "Zinnab from Cairo earned $78,409.33 34 minutes ago.",
    "Al-Hajjar from Dubai earned $62,108.91 45 minutes ago.",
    "Al-Ahmadi from Mecca earned $75,603.27 53 minutes ago.",
    "Yusuf from Baghdad earned $79,804.27 59 minutes ago.",
    "Al-Khatib from Damascus earned $68,103.42 21 minutes ago.",
    "Al-Masri from Amman earned $74,609.48 38 minutes ago.",
    "Al-Qasimi from Abu Dhabi earned $60,504.88 44 minutes ago.",
    "Mustafa from Beirut earned $79,408.36 51 minutes ago.",
    "Ibrahim from Muscat earned $57,309.82 56 minutes ago",
    "Dakota from New York deposited $12,607.96 49 minutes ago.",
    "Cheyenne from Los Angeles deposited $9,902.28 12 minutes ago.",
    "Sequoia from Chicago deposited $14,805.23 37 minutes ago.",
    "Winona from Houston deposited $8,308.14 25 minutes ago.",
    "Tahoma from Philadelphia deposited $10,609.72 18 minutes ago.",
    "Shoshone from Phoenix deposited $11,609.17 21 minutes ago.",
    "Kiona from San Antonio deposited $14,506.92 33 minutes ago.",
    "Navajo from San Diego deposited $6,502.61 45 minutes ago.",
    "Shawnee from Dallas deposited $18,205.07 28 minutes ago.",
    "Tecumseh from San Jose deposited $15,406.51 40 minutes ago.",
    "Greta from Berlin deposited €11,604.57 42 minutes ago.",
    "Friedrich from Munich deposited €14,102.75 15 minutes ago.",
    "Lieselotte from Hamburg deposited €8,509.61 26 minutes ago.",
    "Dieter from Frankfurt deposited €19,001.04 31 minutes ago.",
    "Anneliese from Cologne deposited €10,007.39 39 minutes ago.",
    "Wolfgang from Stuttgart deposited €7,601.93 47 minutes ago.",
    "Brunhilde from Leipzig deposited €7,002.16 4 minutes ago.",
    "Heinrich from Dusseldorf deposited €9,903.68 10 minutes ago.",
    "Liesel from Berlin deposited €17,501.42 22 minutes ago.",
    "Gunther from Hamburg deposited €10,604.92 35 minutes ago.",
    "Alessio from London deposited £9,002.36 55 minutes ago.",
    "Ingrid from Paris deposited €10,408.84 19 minutes ago.",
    "Mateo from Rome deposited €8,503.89 27 minutes ago.",
    "Eszter from Budapest deposited £9,207.18 20 minutes ago.",
    "Lars from Stockholm deposited €13,509.55 38 minutes ago.",
    "Liora from Tel Aviv deposited £7,002.21 44 minutes ago.",
    "Costin from Bucharest deposited €14,005.37 34 minutes ago.",
    "Maëlys from Brussels deposited £12,702.92 24 minutes ago.",
    "Luka from Amsterdam deposited €12,502.06 32 minutes ago.",
    "Vasilis from Athens deposited £15,003.21 43 minutes ago.",
    "Aisha from Riyadh deposited $8,002.58 58 minutes ago.",
    "Omar from Dubai deposited $9,503.78 50 minutes ago.",
    "Fatima from Mecca deposited $17,307.14 52 minutes ago.",
    "Amir from Cairo deposited $12,206.45 59 minutes ago.",
    "Layla from Baghdad deposited $19,302.83 24 minutes ago.",
    "Malik from Damascus deposited $10,207.88 46 minutes ago.",
    "Yasmin from Amman deposited $9,708.68 54 minutes ago.",
    "Jamal from Beirut deposited $10,405.77 17 minutes ago.",
    "Nour from Muscat deposited $16,903.95 37 minutes ago.",
    "Zainab from Riyadh deposited $5,802.44 57 minutes ago."
]
